<template>
  <jf-layout>
    <template slot="head">
      <el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:tbappcontrol:save')" @click=" add">新增</el-button>
      <el-button size="small" type="danger" icon="el-icon-delete" v-if="isAuth('platform-config:tbappcontrol:delete')" :disabled="!selectList.length" @click="remove(null)">删除</el-button>
    </template>

    <el-table slot="body" border :data="list" v-loading="loading" @selection-change="selectChange">
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" prop="appName" label="名称" />
      <el-table-column align="center" label="版本">
        <template slot-scope="{row}">
          <span v-if="row.appType===0">安卓</span>
          <span v-if="row.appType===1">IOS</span>
          <span v-if="row.appType===2">PC</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="appVersion" label="版本号" />
      <el-table-column align="center" prop="appIcon" label="图标">
		 <template slot-scope="scope">
		 	<el-image style="width: 100px; height: 100px" :src="scope.row.appIcon"
		 		:preview-src-list="[scope.row.appIcon]"></el-image>
		 </template>
	  </el-table-column>
      <el-table-column align="center" prop="appVersionDesc" label="版本描述" />
      <el-table-column align="center" prop="appDownloadLink" label="下载链接" />
      <el-table-column align="center" label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" icon="el-icon-edit" v-if="isAuth('platform-config:tbappcontrol:update')" @click="edit(row)">编辑</el-button>
          <el-button size="mini" icon="el-icon-delete" v-if="isAuth('platform-config:tbappcontrol:delete')" @click="remove(row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <jf-page slot="foot" @change="getList" :page.sync="params.page" :limit="params.limit" :total="total" />
    <Form :show.sync="show" :row="row" @add="search" @edit="getList" />
  </jf-layout>
</template>

<script>
import Form from "./form";
export default {
  components: { Form },
  data() {
    return {
      show: false,
      params: {
        page: 1,
        limit: 10,
      },
      list: [],
      loading: false,
      row: {},
      total: 0,
      selectList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.params.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      var res = await this.$get(
        "/platform-config/community/appControl/page",
        this.params
      );
      this.loading = false;
      if (res && res.code === 0) {
        this.list = res.data.list;
        this.total = res.data.total;
      }
    },
    selectChange(list) {
      this.selectList = list;
    },
    add() {
      this.row = {};
      this.show = true;
    },
    edit(row) {
      this.row = row;
      this.show = true;
    },
    remove(row) {
      this.$confirm("确认删除选择的数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (row) {
            var ids = [row.id];
          } else {
            var ids = this.selectList.map((item) => {
              return item.id;
            });
            console.log(ids);
          }
          var res = await this.$del("/platform-config/community/appControl", ids);

          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>
